import React, { Component } from "react";
import weclHome from "./weclHome";
import cusCreditDoc from "./Page/cusCredit";
import cusPrivacyDoc from "./Page/cusPrivacy";
import cusServiceDoc from "./Page/cusService";
import deleteUser from "./Page/delete";
import { Route, Router } from "react-router-dom";
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory();

class App extends Component {
  render() {
    console.log("----App----");
    return (
      <Router history={browserHistory}>
        <Route exact path="/" component={weclHome} />
        <Route exact path="/cusCredit" component={cusCreditDoc} />
        <Route exact path="/cusPrivacy" component={cusPrivacyDoc} />
        <Route exact path="/cusService" component={cusServiceDoc} />

        <Route exact path="/delete" component={deleteUser} />
      </Router>
    );
  }
}

export default App;
