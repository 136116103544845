import React from "react";
import DocumentTitle from "react-document-title";
import { enquireScreen } from "enquire-js";

import "./CommonPage.less";

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});

class PrivacyAgreement extends React.PureComponent {
  state = {
    isMobile,
    // isScroll: false,
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    // window.addEventListener('scroll', this.bindScroll);
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  }

  render() {
    return (
      <div className={"contentWrapper"}>
        <DocumentTitle title="PesoOnline Privacy Policy" />
        <h2>PesoOnline Privacy Policy</h2>
        <p>
          CODEBLOCK LENDING INC. (the “Company” or “CODEBLOCK”), the SEC
          registered entity that maintains and operates the PesoOnline
          application (the “PesoOnline Application”), its assignees, affiliates
          and related corporations doing business in the Philippines, values the
          privacy of PesoOnline users and anyone who uses, accesses, downloads,
          browses or registers for PesoOnline services involving the provision
          of short-term loans online on the PesoOnline website or “PesoOnline
          Application” or other related digital media or media including the
          social media of the PesoOnline Services (each and collectively, the
          “PesoOnline Facility”). This Privacy Policy exists as part of
          CODEBLOCK’s commitment to respect and protect the personal data and
          information belonging to users of the PesoOnline Facility (as defined
          below). We are committed to protecting any and all personal data that
          may be provided by users of or visitors to the PesoOnline Facility.
          This Privacy Policy has been prepared in accordance with the laws of
          the Republic of the Philippines and is intended for the sole use of
          CODEBLOCK. CODEBLOCK commits to comply with Republic Act No. 10173 and
          the Data Privacy Act of 2012 and its implementing rules and
          regulations. This Privacy Policy sets forth the policies of the
          Company, its assignees, affiliates and related companies with respect
          to any and all types of personal information described in this Privacy
          Policy, including sensitive personal information as defined in
          Republic Act No. 10173, also known as the Data Privacy Act of 2012
          (hereinafter referred to as “Personal Information”), which may be
          provided by individual users of the PesoOnline facility (including
          potential lenders or borrowers) (“you”, “User” or “Users”) for
          collection and use by the Company, its assignees, affiliates and
          related companies, from which the personal identity may be apparent or
          reasonably and directly ascertainable.
        </p>
        <p></p>
        <p>
          All names and other information relating to users shall be processed
          in the manner set out in this Privacy Policy. We collect information
          only when necessary and only insofar as is relevant to the user's
          transaction. We retain user information only for as long as is
          required by law or is relevant to the purpose for which it was
          collected.
        </p>
        <p></p>
        <p></p>
        <p>
          You can visit the PesoOnline facility and browse without providing
          personal information. During your visit to the PesoOnline facility,
          you can choose to remain anonymous and we cannot identify users of the
          PesoOnline facility unless you have a PesoOnline account and log in
          with a username and password.
        </p>
        <p></p>
        <p>
          The scope of this Privacy Policy is limited to information we collect
          or receive through your use of the PesoOnline Facility through the
          PesoOnline Website or PesoOnline Application. We are not responsible
          for the actions of third parties or companies, the content of their
          facilities, the use of information you provide to them, or any
          products or services they may offer. Any links to these websites do
          not constitute a partnership, sponsorship or endorsement or
          affiliation with these parties or companies or their brands.
        </p>
        <p></p>
        <p>
          By using the PesoOnline Services in conjunction with the PesoOnline
          Facility through the PesoOnline Website or PesoOnline Application, you
          consent to this Privacy Policy and the processing of your personal
          information (including your Personal Data) in the manner set out in
          this Privacy Policy. If you do not agree to these terms, please do not
          use and exit the PesoOnline Facility.
        </p>
        <p></p>
        <p>Personal Information Collected and Processed</p>
        <p></p>
        <p>
          When a User uses the PesoOnline Facility, including when he/she
          creates or registers an account, uses the PesoOnline App, creates a
          transaction, a User receives a payment, makes a payment using a bank,
          payment gateway, payment provider or through other payment collection
          partners, and/or when a User avails himself/herself of any other
          features (including those in the Members Get Membership Program) or
          products and services of the PesoOnline Facility and products and
          services provided by its platform lenders or service providers,
          whether now available or as may be developed and offered in the
          future, the following personal information (as defined in this Privacy
          Policy and referred to as “Personal Information”) may be collected:
        </p>
        <p></p>
        <p>
          I. Full name, permanent and residential address, contact number, email
          address, date of birth and/or age, gender, employment information,
          bank account details, credit card and/or financial account
          information, financial history and details of government-issued
          identification documents;
        </p>
        <p></p>
        <p>
          We also need to collect your contact information. We will request
          "read contacts" permission. If you agree to the authorization, we will
          scan and upload the contact list information using the most secure
          data transmission protocol (HTTPS). We will store your personal
          information through DES and AES encryption. The storage time will not
          exceed your usage time.
        </p>
        <p></p>
        <p>
          II. Information submitted by users when visiting records and using the
          website;
        </p>
        <p></p>
        <p>
          III. Social media, e-commerce, transportation or other online/mobile
          account details.
        </p>
        <p></p>
        <p>
          IV. Information about users obtained from any third party (including
          employers) ("employment information") and/or other third-party sources
          holding any financial, transaction or payment records of users and
          historical transaction information conducted by users or on behalf of
          users with the user's authorization;
        </p>
        <p></p>
        <p>
          The above information (obtained from users or on behalf of users from
          other sources) should be obtained when users register at PesoOnline
          facilities or users apply to use any PesoOnline products and services,
          and the Company shall collect, use, process, disclose, retain, store
          and protect this information in accordance with this Privacy Policy
          and PesoOnline Terms of Service.
        </p>
        <p></p>
        <p></p>
        <p>Personal information may be collected in the following ways:</p>
        <p></p>
        <p>
          a. Personal information provided by Users during registration, during
          the loan application process, or when using any product or service on
          the PesoOnline Facility. If data is submitted to obtain pre-approval
          and/or obtain a financial product, the Company and applicable Platform
          Lenders shall collect this information.
        </p>
        <p></p>
        <p>
          b. When Users interact with customer service personnel of the
          PesoOnline Facility, such as through telephone, correspondence,
          face-to-face meetings, online chats, social media, messaging
          applications, emails, and other communication channels that are
          available now or may be available in the future;
        </p>
        <p></p>
        <p>
          c. When Users choose to utilize, use or subscribe to third-party
          services through the PesoOnline Facility;
        </p>
        <p></p>
        <p>
          d. When Users register for the PesoOnline Service using their social
          media account or link the PesoOnline account to the User’s social
          media, e-commerce, transportation or other online/mobile accounts or
          use certain other features of the PesoOnline Facility;
        </p>
        <p></p>
        <p>
          e. When a user requests to be contacted, placed on an email or other
          mailing list
        </p>
        <p></p>
        <p>
          f. When a user responds to a promotion, program, or any request for
          additional personal information from the Company or PesoOnline
          Facility;
        </p>
        <p></p>
        <p>
          g. When a user is contacted and responds to marketing representatives
          and customer service personnel from the Company or PesoOnline
          Facility;
        </p>
        <p></p>
        <p>
          h. When the Company receives referrals from business partners and
          third parties, for example, when a user is referred by them (i.e., a
          user uses a Company referral code or member obtains a membership
          program or coupon code);
        </p>
        <p></p>
        <p>
          i. When the Company or its affiliates seek information about a user
          from a third party in connection with PesoOnline Facility’s products
          and services;
        </p>
        <p></p>
        <p>
          j. Personal information collected from publicly available sources,
          including, but not limited to, social media, public repositories,
          websites, and similar sources;
        </p>
        <p></p>
        <p>
          k. Personal data collected on behalf of the user from third parties
          with whom the user transacts, from whom the user obtains, is using or
          has obtained services or products, subject to the authorization and
          consent collection below; and/or
        </p>
        <p></p>
        <p>
          l. Information collected using cookies, web beacons, web crawlers,
          flash cookies, general log information, mobile and browser information
          (in each case in accordance with the user's permission settings in the
          application and such devices), user browsing behavior, user searches
          and transactions, and referral information.
        </p>
        <p></p>
        <p>
          Submission of True and Accurate Information - You must submit to us
          true, accurate and not misleading information (including personal
          data), and you must keep it up to date and inform us of changes. We
          reserve the right to request documentation to verify the information
          you provide.
        </p>
        <p></p>
        <p>
          Voluntary Submission of Personal Information/Personal Data - We can
          only collect your personal data if you voluntarily submit information
          to us. If you choose not to submit your personal information to us or
          subsequently withdraw your consent for us to use your personal
          information, we may not be able to provide you with some or all of the
          services offered through the PesoOnline Application or PesoOnline
          Facilities. You can access and update the personal information you
          submit to us at any time.
        </p>
        <p></p>
        <p>
          Social Media Account Linking – If you register with PesoOnline using a
          social media, e-commerce, transportation, or other online/mobile
          account (“Online Account”), or link your PesoOnline account to your
          Online Account, or use certain other PesoOnline Facility features, we
          may have access to information about you (which you consented to us
          doing when you linked your PesoOnline account to your Online Account)
          that you voluntarily provided to the Online Account provider in
          accordance with the provider’s policies, and we will manage your
          personal data that we collect in accordance with the PesoOnline
          Privacy Policy.
        </p>
        <p></p>
        <p>
          iOS and Android Platforms – When you use the PesoOnline application on
          a mobile platform, we may collect and log certain information, such as
          your unique device ID (persistent/non-persistent), hardware type,
          media access control (“MAC”), address, International Mobile Equipment
          Identity (“IMEI”), your operating system version (“OS”), your device
          name, your email address, your profile information (if connected to
          any social media accounts, including Facebook), and location based on
          your Internet Protocol (“IP”) address. This information is useful to
          us for troubleshooting purposes and helps us understand usage trends.
          Whenever you use the PesoOnline application, we may collect data about
          all your interactions with the PesoOnline application or use of
          certain features of the PesoOnline facility through server log files.
          This data is stored in our cloud servers.
        </p>
        <p></p>
        <p>
          Log Data - When a user or visitor accesses the PesoOnline Facility,
          the user's or visitor's browser may send personal data to the Company,
          which the Company collects for statistical and internal record keeping
          purposes. The process of collecting this information is called "Log
          Data" and may include information such as the computer's IP address,
          browser type, browser version, pages visited on the PesoOnline
          website, time and date of visit, time spent on pages or other channels
          on the PesoOnline Facility (including the PesoOnline Application), and
          other statistics.
        </p>
        <p></p>
        <p>
          Other Collection - We may also obtain information from you in the
          following ways: (1) your visits to and participation in the PesoOnline
          Services and/or PesoOnline Applications or Facilities; (2) your
          participation in surveys regarding the PesoOnline Services and/or
          PesoOnline Applications or Facilities; and/or (3) your participation
          in promotions or contests through the PesoOnline Services and/or
          PesoOnline Applications or Facilities.
        </p>
        <p></p>
        <p>
          Cookies - A cookie is a small text file that requests permission to be
          placed on a visitor's computer hard drive. Cookies may be transferred
          to your computer or device to identify a user's computer or device and
          "remember" information you access through your computer or device,
          such as your preferences or username and password. Once the visitor
          agrees, the file is added and the cookie helps analyze web traffic or
          provide internet usage information. The information contained in the
          cookie may be linked to your personal data and is used for purposes
          such as improving the quality of the PesoOnline Services, tailoring
          recommendations to your interests, and making the PesoOnline Services
          easier to use. Visitors can instruct their browsers to refuse all
          cookies or to indicate when a cookie is being sent. You can disable
          cookies at any time, but you may not be able to access or use some or
          all features of the PesoOnline Services or PesoOnline Applications. A
          cookie in no way gives the Company access to the visitor's computer or
          any information about the visitor, other than the data the visitor
          chooses to share with the Company.
        </p>
        <p></p>
        <p>
          We may provide recommendations from third parties that pass cookies to
          your computer or device in order to track the content you visit and
          the recommendations you see. Third-Party Recommendations – We may
          provide product and retail partner information within the PesoOnline
          Service or PesoOnline Application or Facility. Partners may collect
          and use information about you or your computer or device when you
          visit their sites or facilities, such as your PesoOnline Service
          session activity, device identifiers, MAC address, IMEI, geolocation
          information, and IP address. They may further use this information to
          provide recommendations or product information that may be of interest
          to you. Because third-party partner companies associate your computer
          or device with a number, they can recognize your computer or device
          each time they send you a recommendation or product information. These
          partners may (if you agree and accept their terms or use, privacy
          policy or similar documents) use information about your visit to the
          PesoOnline Application or PesoOnline Facility for the PesoOnline
          Service and third-party websites and applications to measure your
          interaction and response to recommendations and/or product information
          through your computer or device to track performance and provide you
          with better and more appropriate recommendations or product
          information about goods and services that may be of interest to you.
          In addition, you may see advertisements for PesoOnline Services or
          PesoOnline Apps in other services or facilities. By clicking on them,
          you can register to become a user of PesoOnline Services. This Privacy
          Policy does not apply to, and we are not responsible for, the data
          collection practices of these third-party partners, and we encourage
          you to review their privacy policies to learn more about their use of
          cookies and other technologies.
        </p>
        <p></p>
        <p>
          Third-party Services – Our Services may include third-party tracking
          tools from our service providers. Such third parties may use cookies,
          APIs, and SDKs in our Services in order to collect and analyze user
          information on our behalf. Third parties may have access to
          information such as your device identifier, MAC address, IMEI, locale
          (a specific location that uses a specific language), geolocation
          information, and IP address.
        </p>
        <p></p>
        <p>
          Third Party Personal Information - If you provide us with any personal
          information relating to third party individuals (such as information
          about your spouse, children, parents and/or employees, etc.),
          including information submitted for membership access to membership
          programs, related party contacts and third party contacts and other
          reference information, by submitting such information to us, you
          represent and warrant that you have obtained the consent of the third
          party to provide us with their personal information for the respective
          purposes (including sharing and disclosing the information to such
          third parties as necessary) in accordance with this Privacy Policy,
          for use by the Company and Lenders, and thus deemed to be lawfully
          obtained by the Company and Lenders for the purposes and uses listed
          in this Privacy Policy. The Company shall confirm with the above third
          party individuals as soon as possible whether they agree to be listed
          as your reference and provide them with the option to have their
          information remain private and be deleted as your reference. In such
          case, the Company may require you to provide additional character
          proof.
        </p>
        <p></p>
        <p>
          Personal information or personal data processors may process personal
          information on behalf of the Company, in each case with attention to
          the rights of users and data subjects and the obligations of the
          Company under the Data Privacy Act 2012.
        </p>
        <p></p>
        <p>Authorization and Consent to Collection</p>
        <p></p>
        <p>
          User expressly and unconditionally consents to the access, possession,
          collection, storage, processing, use, analysis and/or distribution,
          disclosure, display and delivery of any information relating to User
          (including Personal Information), and the transfer of such information
          to any third party, including but not limited to organizers/operators
          of payment facilities, third-party service providers and third-party
          sellers, credit bureaus such as Credit Information Corporation (“CIC”)
          and government agencies/institutions. User acknowledges and
          authorizes: 1) the periodic submission and disclosure of User’s Basic
          Credit Data (as defined under Republic Act No. 9510 and its
          implementing rules and regulations) to CIC
        </p>
        <p></p>
        <p>
          and any updates or corrections thereto; and 2) the sharing of User’s
          Basic Credit Data with other lenders authorized by CIC and credit
          reporting agencies duly recognized by CIC.
        </p>
        <p></p>
        <p>
          By downloading and using the PesoOnline Application and/or PesoOnline
          Tools, the user hereby expressly consents and authorizes, and is
          deemed to make the following declarations:
        </p>
        <p></p>
        <p>
          "By downloading PesoOnline, I hereby consent to the collection and
          processing of my personal information for legitimate business
          purposes, including but not limited to determining my credit score and
          providing loans. I hereby certify that all information provided by me
          is true and correct and any misrepresentation or false information
          therein shall be deemed an act of fraud against CB Express Techsystem
          Corporation (CODEBLOCK), its partners and affiliates. I authorize
          CODEBLOCK to verify and investigate the above statements/information
          based on the references provided and other reasonable sources. For
          this purpose, I hereby waive my right to confidentiality of customer
          information and expressly consent to the processing of any personal
          information and records relating to me that may be obtained from third
          parties, including government agencies, my employer, credit bureaus,
          business partners and other entities you deem appropriate and
          sufficient in the conduct of my business, whether sensitive or
          otherwise, in accordance with Republic Act No. 10173, for the purpose
          of determining my eligibility for the loan requested. I also agree
          that the application, all supporting documents and any other
          information related to this application shall be used by CODEBLOCK and
          communicated to CODEBLOCK and such information shall remain the
          property of CODEBLOCK regardless of whether my credit score is
          determined or the loan is approved. I expressly and unconditionally
          authorize CODEBLOCK to disclose any information about me to any bank
          or affiliate and other financial institution, including to the Central
          Bank of the Philippines, the Securities and Exchange Commission, the
          National Privacy Commission, the Anti-Money Laundering Council, credit
          reporting agencies, and/or any other government agency for processing
          such information. Specifically, I hereby acknowledge and authorize: 1)
          the periodic submission and disclosure of my basic credit data (as
          defined in Republic Act No. 9510 and its implementing rules and
          regulations) to the Credit Information Corporation (CIC) and any
          updates or corrections thereto; and 2) the sharing of my basic credit
          data with other lenders authorized by CIC and credit reporting
          agencies duly recognized by CIC.”
        </p>
        <p></p>
        <p></p>
        <p>
          The User hereby consents and authorizes CODEBLOCK and lenders and/or
          third party partners (such as but not limited to CIC, Robinsons Bank
          Corporation, Robinsons Retail Holdings Inc, Cebu Air, Inc., Meralco,
          PLDT, Smart Telecoms, Globe Telecom, TransUnion, CIBI, Compuscan and
          ShareTreats) to provide services including user profiling, identity
          checking and verification, fraud detection, monitoring and prevention,
          credit investigation, credit scoring and credit collection, obtaining
          relevant credit, transactional and personal data from their employers,
          banks, credit card companies, professionals, telecommunications
          companies, IT services and other relevant institutions in the course
          of investigation, verifying any and all information collected about
          the User,
        </p>
        <p></p>
        <p>
          collecting any and all information related thereto, and processing any
          such information, whether from third party related sources. The User
          further acknowledges, understands, accepts and agrees that the User’s
          Personal Data may be shared with businesses and/or marketing partners,
          companies (i.e., registration using a referral code, link, banner or
          similar referral identification method) or individuals (i.e., Member
          to Member) who referred the User to register, obtain a loan or
          otherwise obtain or use the services of PesoOnline’s facilities using
          such companies’ or individuals’ unique identifiers (i.e., Reference
          Codes).
        </p>
        <p></p>
        <p>
          User hereby consents and authorizes CODEBLOCK and its subsidiaries and
          affiliates, banks, credit card companies and the institutions
          mentioned in the previous paragraph to release any and all information
          required by CODEBLOCK, lenders, CODEBLOCK and its subsidiaries and
          affiliates and/or its third-party partners, such as but not limited to
          the following:
        </p>
        <p></p>
        <p>a. Identity and credit information;</p>
        <p></p>
        <p>b. Voice call data records;</p>
        <p></p>
        <p>c. SMS sending and receiving records;</p>
        <p></p>
        <p>d. Data usage records;</p>
        <p></p>
        <p>e. Promotion subscription and redemption records;</p>
        <p></p>
        <p>f. Customer relationship management records;</p>
        <p></p>
        <p>g. Borrower status; and</p>
        <p></p>
        <p>h. Borrower recovery rate.</p>
        <p></p>
        <p>i. Financial assets and liabilities</p>
        <p></p>
        <p>j. Periodic expenses and expenditures</p>
        <p></p>
        <p>
          k. Outstanding loans and/or liabilities of other financial
          institutions
        </p>
        <p></p>
        <p>l. Retail spending records</p>
        <p></p>
        <p>m. Information about fraud detection, monitoring and prevention</p>
        <p></p>
        <p>n. Contact list information</p>
        <p></p>
        <p>o. Application list information</p>
        <p></p>
        <p>
          All relevant data/information is reported/stored on
          https://cash.peso-online.com/api. Information is collected only with
          your consent. Data will be transmitted and uploaded via a secure
          connection (https).
        </p>
        <p></p>
        <p>Use/Purpose of Personal Data</p>
        <p></p>
        <p>
          Personal data shall be processed, collected, used, disclosed, stored
          and retained for the following purposes:
        </p>
        <p></p>
        <p>i. Identify users;</p>
        <p></p>
        <p>
          ii. Contact users regarding accounts registered or information
          requested;
        </p>
        <p></p>
        <p>
          iii. Process account registrations as part of investor, borrower and
          loan application screening;
        </p>
        <p></p>
        <p>iv. Maintain internal records;</p>
        <p></p>
        <p>
          v. Market and offer new, related or complementary products and
          services to users;
        </p>
        <p></p>
        <p>vi. Conduct background checks and identity verification;</p>
        <p></p>
        <p>
          vii. Conduct and perform fraud monitoring, detection, analysis and
          prevention;
        </p>
        <p></p>
        <p>
          viii. Prevent, detect and investigate actual or suspected crimes,
          analyze and manage business risks;
        </p>
        <p></p>
        <p>ix. Perform data analysis and tracking;</p>
        <p></p>
        <p></p>
        <p>
          x. To conduct market research that enables CB to understand and
          determine the location, preferences and demographics of customers in
          order to develop special offers, products or marketing programs
          related to products and services and to improve (i.e. customize offers
          and products) such products and services;
        </p>
        <p></p>
        <p>
          xi. To conduct investigations related to disputes, payments, suspected
          illegal activities or fraud;
        </p>
        <p></p>
        <p>
          xii. To carry out marketing and promotional activities related to the
          Company, CODEBLOCK local branches, CODEBLOCK partners and CODEBLOCK
          products and services, whether by electronic means, email and post, or
          by telephone, SMS or social media, or by sending newsletters (e.g. to
          let users know about new products or features and to promote events,
          competitions, demonstrations, loyalty programs and other activities
          organized by CODEBLOCK), also using automated means. Such
          communications will relate to products and services such as financial
          products, services, etc. In addition, CODEBLOCK will process and share
          user data for market research, statistical analysis or other research
          to improve its products, develop new products and analyze customer
          satisfaction;
        </p>
        <p></p>
        <p>
          xiii. Analyze user behavior or conduct user profiling (for example, in
          relation to financing products obtained, offered, viewed and searched)
          in order to improve and develop better products and services in the
          facility, including providing better matching based on the respective
          priorities of lenders and borrowers;
        </p>
        <p></p>
        <p>
          xiv. Develop, enhance and maintain offline and online risk assessment
          processes and models;
        </p>
        <p></p>
        <p>
          xv. Develop and generate credit scores, credit models and user models;
        </p>
        <p></p>
        <p>
          xvi. Perform insurance, credit risk analysis, credit investigation,
          credit scoring and credit collection on users;
        </p>
        <p></p>
        <p>
          xvii. Implement, monitor, manage and analyze the PesoOnline facility,
          and develop, enhance and upgrade features, functions, products and
          services in the PesoOnline facility, including promotions, loyalty and
          rewards programs;
        </p>
        <p></p>
        <p>xviii. Respond to your questions and requests;</p>
        <p></p>
        <p></p>
        <p>
          xix. Facilitate CODEBLOCK's business asset transactions (which may
          extend to any merger, acquisition or asset sale);
        </p>
        <p></p>
        <p>
          xx. Facilitate and, as required, as part of any financing or financing
          activity (i.e. obtaining a loan, credit, pledge, assignment, transfer
          as a form of security or loan collateral);
        </p>
        <p></p>
        <p>
          xxi. Establish and maintain communications with users by email
          (e-mail), SMS (Short Message Service), registered mail, telephone or
          physical visits;
        </p>
        <p></p>
        <p>
          xxii. For quality assurance, employee training and performance
          evaluation, and identity verification purposes;
        </p>
        <p></p>
        <p>
          xxiii. Provide information to external parties such as government
          agencies and others as part of scientific research and/or learning;
        </p>
        <p></p>
        <p>
          xxiv. For any other legal and/or regulatory purposes (including, but
          not limited to, obtaining legal advice and dispute resolution) and
          legitimate business purposes and perform all;
        </p>
        <p></p>
        <p>
          xxv. PesoOnline uploads and transmits your contact list data,
          including phone numbers and names in your mobile phone. The uploaded
          and transmitted phone numbers and names will be used to manage risks
          and to periodically send contact list data to our servers. PesoOnline
          will never share data with third parties without your permission.
          Information is only collected with your consent and authorization. The
          data will be transferred and uploaded via a secure connection (https).
        </p>
        <p></p>
        <p>
          xxvi. By providing us with your home and/or mobile phone number, we
          have the right to contact you or the contact number you authorize us
          to use. This also allows us to use text messages, artificial or
          pre-recorded voice messages, and auto-dialing technology for all
          purposes not prohibited by applicable law. Message and data rates may
          apply. You can change these preferences at any time by contacting us.
          We may also send emails to any address we reasonably believe you can
          be contacted (including reference addresses you authorize). The
          purposes of calls and messages include: suspected fraud or identity
          theft; obtaining information; transactions or services on your
          account; and collecting money from your account or collecting
          delinquent accounts. Please make sure you have told them that they
          will receive text messages, calls or emails from us asking for your
          loan information. When PesoOnline communicates with the relevant
          customer contact you provide, if that contact has been expressly
          rejected and told to stop contacting, PesoOnline will not call or
          otherwise contact that contact. The rights granted to us by this
          clause extend to our affiliates, subsidiaries, parent companies,
          agents, suppliers, and anyone who has such a connection with the owner
          of any note on which you obtain a loan.
        </p>
        <p></p>
        <p>
          xxvii. PesoOnline collects a list of installed app metadata
          information for each app installed on your device, including app name,
          package name, installation time, update time, version name and version
          code. This is used to identify and analyze user behavior and risk
          across multiple loans. PesoOnline will never share data with third
          parties without your permission. Information is collected only with
          your consent. Data is transmitted and uploaded over a secure
          connection (https).
        </p>
        <p></p>
        <p>
          CODEBLOCK, its subsidiaries and affiliates are subject to
          administrative, operational and legal obligations in accordance with
          current laws and regulations.
        </p>
        <p></p>
        <p>
          As some practical examples related to the uses and purposes listed
          above, we may use User Information to:
        </p>
        <p></p>
        <p>
          a. Allow Users to log in and make purchases on the marketplace
          platform under or affiliated with the PesoOnline Service;
        </p>
        <p></p>
        <p>
          b. Identify and recommend products and services offered through or in
          connection with the PesoOnline Facility and/or PesoOnline Application;
        </p>
        <p></p>
        <p>
          c. Operate and improve the PesoOnline Service and/or PesoOnline
          Application;
        </p>
        <p></p>
        <p>
          d. Understand User preferences to enhance User’s experience and
          enjoyment of the PesoOnline Service and/or PesoOnline Application;
        </p>
        <p></p>
        <p></p>
        <p>e. Respond to user comments and questions or requests;</p>
        <p></p>
        <p>
          f. Provide and deliver products and services as requested or needed by
          users;
        </p>
        <p></p>
        <p>
          g. Send relevant information to users, including confirmations,
          technical notices, updates, security alerts, support and
          administrative messages;
        </p>
        <p></p>
        <p>
          h. Communicate news to users about promotions, rewards, upcoming
          events and other products and services offered by PesoOnline and its
          selected partners; and
        </p>
        <p></p>
        <p>
          i. Link or combine with other information provided to us by third
          parties to help understand user preferences so as to provide better
          services to users.
        </p>
        <p></p>
        <p>Sharing of Personal Information</p>
        <p></p>
        <p>
          Subject to applicable laws, regulations and rules, CODEBLOCK and its
          affiliates may share your personal information in the conduct of their
          business for processing as necessary for the maintenance of PesoOnline
          facilities and the performance of its duties and responsibilities.
          CODEBLOCK or its subsidiaries or entities within its network of
          affiliates may be engaged as subcontractors or data processors, in
          each case, as needed for the above purposes.
        </p>
        <p></p>
        <p>
          Personal information associated with lenders may be shared with
          borrowers using PesoOnline services. As a borrower, your personal
          information will be shared with lenders for the purpose of assessing
          your credit history and other information required by lenders to
          confirm your credit history and credit score and to determine or
          assist in determining decisions regarding borrower screening,
          registration, pre-approval of lender financial products and services,
          or loan applications. Your information may also be shared with
          third-party purchasers of any loan, including distressed loans. If
          your loan or other financial product is assigned, conveyed, taken
          over, purchased or otherwise transferred to any other party, all of
          your records, transactions, any other information (including personal
          and sensitive personal information) relating to the issuance, history,
          basis and status of such loan or financial instrument shall be shared,
          transferred and delivered to that third party.
        </p>
        <p></p>
        <p>
          Generally, User Personal Information may be disclosed to the following
          parties:
        </p>
        <p></p>
        <p>
          (a) Company’s affiliates and subsidiaries, agents (including
          collection agencies) and subcontractors;
        </p>
        <p></p>
        <p>
          (b) Government, regulatory and anti-fraud agencies for the purpose of
          identifying, preventing, detecting or combating fraud, money
          laundering or other crime, and/or for other lawful purposes;
        </p>
        <p></p>
        <p>
          (c) Other entities as may be required by law, or when Company believes
          disclosure is appropriate to comply with the law, enforce or apply
          Company’s rights, or protect the rights, property or safety of
          Company, PesoOnline facilities or PesoOnline users. This includes
          exchanging information with other companies, organizations or
          regulators for fraud protection purposes;
        </p>
        <p></p>
        <p>
          (d) To the extent permitted in the public interest, in the manner set
          out in this Privacy Policy, for the purposes described herein;
        </p>
        <p></p>
        <p>
          (e) Third-party credit organizations, credit bureaus, credit rating
          companies, fraud analysis and detection organizations and associations
          (public and private), including Credit Information Companies (defined
          above as “CICs”), and government agencies/institutions. For the
          avoidance of doubt, User acknowledges and authorizes: 1) the periodic
          submission and disclosure of User’s Credit Data (as defined in
          Republic Act No. 9510 and its Implementing Rules and Regulations) and
          any updates or corrections thereto to CIC; 2) the sharing of User’s
          Basic Credit Data with other lenders authorized by CIC and credit
          reporting agencies duly recognized by CIC;
        </p>
        <p></p>
        <p>
          (f) third party partners, including but not limited to CIC, Robinsons
          Bank Corporation, Robinsons Retail Holdings Inc, Cebu Air, Inc.,
          Meralco, PLDT, Smart Telecoms, Globe Telecom, TransUnion, CIBI,
          Compuscan and ShareTreats, for the purpose of identity verification
          and verification, credit analysis and investigation, risk analysis and
          verification, credit scoring and credit collection, which shall
          include the collection of relevant credit information from User’s
          employer, bank, credit card company and other relevant agencies during
          the investigation process, to verify any and all information collected
          about User, to collect any and all information related thereto and to
          process any such information from any relevant sources;
        </p>
        <p></p>
        <p>
          (g) business partners, investors, assignees or transferees (actual or
          potential) in furtherance of the CODEBLOCK business asset transactions
          for all or part of the business (which may extend to any merger,
          acquisition or asset sale or financing);
        </p>
        <p></p>
        <p>(h) third parties who purchase or take over any Loan;</p>
        <p></p>
        <p>
          (i) third parties hired, used or otherwise authorized by a Lender or
          Borrower to provide any support or services for any transaction
          entered into between the Lender and Borrower (such as aggregators,
          payment gateways, payment providers, collection agents and banks); and
        </p>
        <p></p>
        <p>
          (j) collection and recovery agents and/or agencies in connection with
          the enforcement of repayment obligations on Loans or other debts.
        </p>
        <p></p>
        <p>
          You as a user hereby acknowledge and agree that CODEBLOCK may share
          any and all information relating to you as a user with third parties
          for the purposes set out in this Privacy Policy for any legitimate
          business purpose including but not limited to credit checks,
        </p>
        <p></p>
        <p>
          credit scoring, credit collection, fraud detection, fraud prevention,
          fraud monitoring, product and system development, sales and marketing
          activities, communications related to the development of its products
          and/or services, etc.
        </p>
        <p></p>
        <p>
          All data processing, subcontracting or data sharing agreements shall
          comply with the requirements of the Data Privacy Act 2012 or
          applicable laws and regulations that may be adopted and implemented
          thereafter. If such processing, subcontracting or data sharing
          requires notice or consent, we will provide or request such notice
          and/or consent before processing or sharing your personal data.
        </p>
        <p></p>
        <p>
          Please note that if you agree to share information with other third
          parties for their own marketing purposes, the processing of your
          personal information shall be subject to their separate privacy
          policies and you should contact those third parties directly if you
          wish to opt-out of receiving marketing or promotional emails from
          those third parties.
        </p>
        <p></p>
        <p>
          We may release your information (including personal information) where
          permitted by law or where we believe release is necessary to comply
          with the law, enforce or apply our rights, or protect the rights,
          property or safety of us or our users or others. This includes
          exchanging information with other companies and organizations for
          fraud protection. We may share your information in the context of any
          merger, sale of assets, or financing or acquisition of all or a
          portion of our business to another company. We may share aggregated or
          anonymous information about you with advertisers, publishers, business
          partners, sponsors, and other third parties.
        </p>
        <p></p>
        <p>
          From time to time, we may share or obtain user-level data collected
          about you from third parties (including affiliated companies) for
          purposes other than the specific examples noted in this Privacy
          Policy. User-level data is information associated with your specific
          device and is not aggregated with other data. For example, we may
          share with or obtain data from third parties that indicates that you
          viewed certain websites or used certain applications or features, but
          you will not be personally identified by such information. Instead,
          the user-level data will be associated with an anonymous identifier
          (e.g., user "X").
        </p>
        <p></p>
        <p></p>
        <p>Storage of Personal Data</p>
        <p></p>
        <p>
          The data we collect from you, including Personal Data, may be
          transferred to a destination outside the Philippines and stored there.
          The data may also be processed by staff working for us or for our
          suppliers outside the Philippines. Such staff may be involved in
          fulfilling your request. By submitting your Personal Data, you consent
          to such data transfer, storing or processing. We will take all steps
          reasonably necessary to ensure that your data is treated securely and
          in accordance with this Privacy Policy. We may also disclose your data
          to anyone we deem necessary in order to provide you with
          account-related services.
        </p>
        <p></p>
        <p>
          If we give you (or if you choose) a password or PIN that enables you
          to
        </p>
        <p></p>
        <p>
          access certain parts of the PesoOnline facilities, you are responsible
          for keeping this password confidential. We ask you not to share your
          password with anyone. Unfortunately, the transmission of information
          via the internet is not completely secure. Although we will do our
          best to protect your Personal Data, we cannot guarantee the security
          of data transmitted through the PesoOnline facilities and any
          transmission is at your own risk. Once we have received your
          information, we will use strict procedures and security features to
          try to prevent unauthorized access.
        </p>
        <p></p>
        <p>Retention of Your Personal Data</p>
        <p></p>
        <p>
          The Company will retain your Personal Data for the duration of your
          activities and transactions in connection with the products and
          services offered on this PesoOnline Facility and/or as required for
          legal and regulatory and/or other legitimate business purposes and
          will dispose of it in a secure manner to prevent further processing,
          unauthorized access or disclosure to any other party or the public or
          to your benefit, provided that the Company may retain copies of your
          Personal Data in the Company’s archives to determine its ongoing
          obligations or in accordance with its bona fide record retention or
          data backup policies, access to which shall be restricted as required
          by applicable laws and regulations.
        </p>
        <p></p>
        <p>
          Likewise, Personal Information may be retained for fraud prevention,
          detection and monitoring purposes in accordance with the Company’s
          Fraud Detection and Prevention Policy and any requirements imposed by
          any credit agency, bureau or institution for the purpose of preventing
          fraud, identity theft, anti-money laundering, terrorist financing and
          other similar reasons consistent with public policy and public
          interest.
        </p>
        <p></p>
        <p>Security of Your Personal Data</p>
        <p></p>
        <p>
          The Company values ​​the Personal Data collected, used, retained and
          stored through the PesoOnline Facility. The Company has implemented
          technical, organizational and physical measures to protect your
          Personal Data,
        </p>
        <p></p>
        <p>
          We take reasonable steps to protect your information from unauthorized
          access or loss, misuse or alteration by third parties.
        </p>
        <p></p>
        <p>
          Although we do our best to store information collected on PesoOnline
          facilities and/or PesoOnline applications in a secure operating
          environment that is not open to the public, we cannot guarantee the
          absolute security of that information while in transit or while stored
          on our systems. In addition, while we attempt to ensure the integrity
          and security of our network and systems, we cannot guarantee that our
          security measures will prevent third-party "hackers" from illegally
          gaining access to such information. We do not warrant or represent
          that your information will be protected from loss, misuse, or
          alteration by third parties.
        </p>
        <p></p>
        <p>Access and Correction of Personal Data</p>
        <p></p>
        <p>
          If you wish to exercise the rights of access, correction,
          cancellation, portability and objection described below, or to make
          complaints and other inquiries, please send a registered mail to BA
          Lepanto Building, 7th Floor, 8747 Roxas Avenue, Makati City,
          Philippines 1227, or send an email to service@pesoonline.com,
          Attention: Data Privacy Officer, Eugene Chua.
        </p>
        <p></p>
        <p>
          • Receive details of the origin of the data, the purposes and methods
          of processing, the processing logic and, if the latter is carried out
          by electronic means, the identity of the data controller and data
          processor, the entities or categories of entities to which the data
          may be communicated and the entities to whom the data may be made
          known;
        </p>
        <p></p>
        <p>
          • Update, correct, supplement or delete data, block or anonymize data
          processed unlawfully, including the retention of data that is not
          necessary for the purposes for which such data were collected or
          subsequently processed; provide the entity receiving the data with
          proof that the requested operation has been completed, as well as the
          content of the operation, unless this requirement proves impossible or
          would require a manifestly disproportionate effort compared to the
          protected right; receive the personal data concerning you and copy or
          transmit them to another data controller (right to data portability);
          and
        </p>
        <p></p>
        <p>
          • Object to the processing of data on legitimate grounds, although
          they are relevant to the purposes for which they were collected, or
          partially (e.g. only in relation to certain means of communication),
          to the processing of data for the purpose of sending advertising
          material or direct sales, market research, commercial information,
          profiling purposes or simply receiving communications by traditional
          means.
        </p>
        <p></p>
        <p>
          Please note that if your personal information has been provided to us
          by a third party (e.g. a referrer), you should contact that
          organization or individual to make such inquiries, complaints, access
          and correction requests.
        </p>
        <p></p>
        <p>Controlling Your Personal Information</p>
        <p></p>
        <p>
          Users retain control over their personal information and may modify
          the personal information registered in the PesoOnline facility
          database.
        </p>
        <p></p>
        <p>
          Users shall be responsible for maintaining the confidentiality of
          their own PesoOnline account and password, as well as any and all
          applications submitted, obligations agreed to or assumed, and all
          other activities occurring under the account. User agrees to
          immediately notify the Company of any unauthorized use or disclosure
          of User's account or password, any unauthorized activity under User's
          PesoOnline account, or any other breach of security.
        </p>
        <p></p>
        <p>
          The Company shall not be liable for any losses incurred by User as a
          result of a third party using User's PesoOnline account or password,
          whether or not User is aware of it and regardless of any fault or
          negligence of the Company.
        </p>
        <p></p>
        <p>
          Users may be held liable for any damages or losses incurred by the
          Company as a result of a third party using User's PesoOnline account
          or password, regardless of any fault or negligence of the Company.
        </p>
        <p></p>
        <p></p>
        <p>Third-party services</p>
        <p></p>
        <p>
          Our services may include third-party tracking tools from our service
          providers. Such third parties may use cookies, APIs, and SDKs in our
          services so that they can collect and analyze user information on our
          behalf. Third parties may have access to information such as your
          device identifier, MAC address, IMEI, locale (specific information
          about using a specific language), geolocation information, and IP
          address.
        </p>
        <p></p>
        <p>Opting out of marketing communications</p>
        <p></p>
        <p>
          You can opt out of receiving promotional emails or other notification
          channels from us by following the instructions in those emails or
          notification channels. If you opt out, we may still send you
          non-promotional emails or notifications, such as those about your
          account or our ongoing business, including where appropriate
          notifications are required by law or regulation, for user protection
          purposes, or where the company deems it necessary to provide users
          with updated information through written notification. Please note
          that if you agree to share information with other third parties for
          their own marketing purposes, the processing of your personal
          information will be subject to their separate privacy policies, and if
          you wish to opt out of marketing through promotional emails or other
          notification channels from these third parties, you should contact
          these third parties directly.
        </p>
        <p></p>
        <p>User Obligations</p>
        <p></p>
        <p>
          You agree to promptly notify us in writing (and in any event within 14
          calendar days) of any changes to the information you provide to us
          from time to time and to respond promptly to any requests from us.
        </p>
        <p></p>
        <p>If:</p>
        <p></p>
        <p>
          • You fail to promptly provide any user information or personal
          information that we reasonably request;
        </p>
        <p></p>
        <p>
          • You refuse or withdraw any consent that we may need to process,
          transfer or disclose personal information for the purposes set out
          above in this Privacy Policy (other than for purposes related to
          marketing or promoting products and services to you); and/or
        </p>
        <p></p>
        <p>
          • We or members of CODEBLOCK suspect financial crime or related risks.
        </p>
        <p></p>
        <p>We may:</p>
        <p></p>
        <p>
          • Be unable to provide you with new PesoOnline services, or continue
          to provide all or part of PesoOnline services
        </p>
        <p></p>
        <p>and reserve the right to terminate our relationship with you;</p>
        <p></p>
        <p>
          • Take necessary actions for us or members of CODEBLOCK to comply with
          compliance or regulatory obligations; and/or
        </p>
        <p></p>
        <p>
          • Suspend, transfer or close your account where permitted by local
          law.
        </p>
        <p></p>
        <p>Data Privacy Information Rights</p>
        <p></p>
        <p>
          Users have the following rights in relation to their personal data:
        </p>
        <p></p>
        <p>
          1.1 Right to request special privacy protections. You have the right
          to request restrictions on certain uses and disclosures of your
          personal data by making a written request specifying what information
          you want to limit and what restrictions you want us to impose on our
          use or disclosure of that information. If you tell us not to disclose
          the information, we will comply with your request unless we must
          disclose the information for treatment or legal reasons. We reserve
          the right to accept or refuse any other request and will notify you of
          our decision.
        </p>
        <p></p>
        <p>
          1.2 Right to inspect and copy. You have the right to inspect and copy
          your personal data, subject to certain exceptions. To access your
          personal data, you must submit a written request detailing the
          information you want to access, whether you want to inspect or get a
          copy, and, if you want a copy, your preferred form and format. We will
          provide copies in the form and format you request if copies are
          readily available, or we will provide you with an alternative format
          you find acceptable, or if we cannot agree and we maintain the record
          in an electronic format, you may choose a readable electronic or hard
          copy format. We will also send copies to any other person you specify
          in writing. We will charge a reasonable fee that covers our labor,
          supplies, postage, and (if requested and agreed in advance) the cost
          of preparing an explanation or summary. In limited circumstances, we
          may refuse your request. If we deny your request to access your
          personal records or the personal records of an individual you
          represent because we believe that allowing access could cause
          significant harm to a client, you have the right to appeal our
          decision.
        </p>
        <p></p>
        <p>
          1.3 Right to Request Confidential Communications. You have the right
          to request that you receive your personal information in a specific
          manner or at a specific location. You can ask us to send information
          to a specific email account or to your work address. We will comply
          with all reasonable requests submitted in writing that specify how or
          where you would like to receive these communications.
        </p>
        <p></p>
        <p>
          1.4 Right to Amend or Supplement. You have the right to ask us to
          amend personal information that you believe is inaccurate or
          incomplete. You must make your request for an amendment in writing and
          state why you believe the information is inaccurate or incomplete. If
          we do so, we may deny your request if we did not create the
          information, if you are not allowed to inspect or copy the relevant
          information, or if the information is accurate and complete, and you
          are not entitled to the information. All information related to any
          request for amendment will be retained and disclosed together with any
          subsequent disclosure of the disputed information.
        </p>
        <p></p>
        <p>
          1.5 Right to Revoke Authorization. The Company must obtain your
          consent to lawfully collect and process your personal data. You may
          revoke (withdraw) your consent at any time. For the avoidance of
          doubt, this will not invalidate any prior processing by the Company
          based on the consent initially given.
        </p>
        <p></p>
        <p>
          1.6 Right to Erasure or Blocking. You have the right to suspend,
          withdraw or order the blocking, erasure or destruction of your
          personal data. You can exercise this right upon discovery and
          sufficient evidence of any of the following:
        </p>
        <p></p>
        <p>
          i. Your personal data is incomplete, out of date, false or illegally
          obtained.
        </p>
        <p></p>
        <p>ii. It is used for a purpose you did not authorize.</p>
        <p></p>
        <p>
          iii. The data is no longer necessary for the purpose for which it was
          collected.
        </p>
        <p></p>
        <p>
          iv. You decide to withdraw consent, or you object to the processing
          and there is no overriding legal basis for the processing.
        </p>
        <p></p>
        <p>
          v. The data concerns information that is detrimental to customers -
          unless there is a legitimate reason for freedom of speech, expression
          or the press; or otherwise authorized by law.
        </p>
        <p></p>
        <p>vi. The processing is unlawful.</p>
        <p></p>
        <p>
          vii. The Company or its personal data processor has violated your
          rights as a customer.
        </p>
        <p></p>
        <p>
          1.7 Right to be notified of a breach. You have the right to be
          notified of a personal data breach, as required by law. In addition to
          protecting you from unfair personal data collection practices, this
          right requires the Company to notify you promptly if your personal
          data has been compromised. If you have provided us with a current
          email address, we may communicate information related to the breach by
          email. 1.8 Right to receive a paper or electronic copy of this notice.
          You have the right to be notified of our legal obligations and privacy
          practices with respect to your personal data, including the right to a
          paper copy of this Privacy Notice, even if you have previously
          requested to receive this Privacy Notice by email.
        </p>
        <p></p>
        <p>
          1.8 Right to Damages. You have the right to be compensated for any
          damages caused by your personal data being inaccurate, incomplete,
          out-of-date, false, illegally obtained, or used without authorization.
          If your rights have been violated, you may file a complaint with the
          National Privacy Commission.
        </p>
        <p></p>
        <p>User and Reference Person Disclosure Statement</p>
        <p></p>
        <p>User Statement</p>
        <p></p>
        <p>
          2.1 Payment. We use and disclose your personal information to other
          providers, affiliates or parent companies and other vendors under or
          working with the PesoOnline Services to obtain payment for the
          services we provide.
        </p>
        <p></p>
        <p>
          2.2 Payment Reminders. We may use and disclose your personal
          information to contact you and remind you of payments due.
        </p>
        <p></p>
        <p>
          2.3 Research. You agree that your personal information may be used for
          research purposes; however, access is granted strictly on a
          need-to-know basis to protect client confidentiality involving
          personal information. Additionally, your personal records may be used
          for data analysis to create customer demographics and trends; however,
          these reports are generated anonymously and customer identities are
          anonymized.
        </p>
        <p></p>
        <p>
          2.4 Marketing. We may use your contact information to communicate with
          you and provide you with product, promotional or service information
          related to your loan application or other services provided by
          PesoOnline, its affiliates or parent companies, and other business
          vendors that may be of interest to you. We may also contact you to
          ensure customer satisfaction with the services provided, including
          identifying your concerns to ensure they are resolved.
        </p>
        <p></p>
        <p>
          2.5 Legal Requirements. We will use and disclose your personal data as
          required by law, but we will limit our use or disclosure to those that
          are inconsistent with the law. When we are required by law to report
          abuse or respond to judicial or administrative proceedings or law
          enforcement officials, we will further comply with the following
          requirements regarding those activities. We may disclose your personal
          data to supervisory authorities for activities authorized by law.
          These supervisory activities include audits, investigations,
          inspections, and licensing.
        </p>
        <p></p>
        <p>
          2.6 Law Enforcement. We may (and are sometimes required by law)
          disclose your personal information to law enforcement officials for
          purposes such as identifying or locating a suspect, fugitive, material
          witness or missing person, complying with a court order, warrant,
          subpoena and other law enforcement purposes.
        </p>
        <p></p>
        <p>
          2.7 Other Uses and Disclosures: If we wish to use your personal
          information for purposes not discussed in this Privacy Statement, we
          will separately obtain your written consent. Reference, Related Party
          Contact and Third Party Contact Statement:
        </p>
        <p></p>
        <p>
          3. Additional Information. If a PesoOnline user identifies you as a
          reference, related party contact or third party contact, we may
          contact you to request additional information about the user who
          designated you as their reference, to verify the information provided
          in the user's application, and/or other information, such as the
          user's new address and/or new contact number,
        </p>
        <p></p>
        <p>
          If we have material information about a user (for example, if a
          borrower defaults on their payment obligations to us) and we are
          unable to contact them using the contact information that the user or
          borrower provided to us.
        </p>
        <p></p>
        <p>
          User Statement on Verification with Affiliate Contacts and Third-Party
          Contacts
        </p>
        <p></p>
        <p>
          4.1 If you provide information for designated Affiliate Contacts
          and/or Third-Party Contacts, you understand, accept and expressly and
          unconditionally consent to the sharing and disclosure of any
          information relating to you (including your personal data and/or
          sensitive personal information) to your designated Affiliate Contacts
          and/or Third-Party Contacts, as such Affiliate Contacts and
          Third-Party Contacts may change from time to time, for the purpose of:
        </p>
        <p></p>
        <p>
          (a) verifying any or all information relating to you (whether
          submitted by you or obtained from other sources) before, during and
          after the application, approval and termination of the Loan Agreement;
        </p>
        <p></p>
        <p>
          (b) inquiring, requesting, ascertaining or determining your
          whereabouts, contact information, financial status and other relevant
          information (including making such inquiries during the loan
          collection process);
        </p>
        <p></p>
        <p>
          (c) for investigation of fraud, theft or other matters relating to any
          suspected or actual criminal conduct or activity, in each case for the
          purpose of reviewing, processing, approving, managing, collecting,
          servicing, selling, transferring, modifying or otherwise relating to
          existing or future loans and in accordance with any mandatory
          applicable laws or regulations that may be issued from time to time.
        </p>
        <p></p>
        <p>
          4.2 In some cases, we also share personal data with our suppliers,
          including CODEBLOCK entities and other business partners who provide
          services to us, such as IT and technology service providers, marketing
          providers, communications services and printing providers, debt
          collection, tracing, debt recovery, fraud prevention and credit
          reference agencies, etc. When we do this, we take steps to ensure that
          they meet our data security standards and therefore keep your personal
          data safe.
        </p>
        <p></p>
        <p>
          4.3 If you no longer want us to share your personal data with such
          persons or wish to change your preferences in this regard, please
          notify us in writing (dpo@peso-online.com). When we provide your
          personal data to any of the above recipients, that recipient may
          process your personal data on our behalf or for its own purposes. If
          the recipient processes personal data for its own purposes, it will be
          responsible for complying appropriately with any applicable laws in
          relation to your personal data.
        </p>
        <p></p>
        <p>Changes to our Privacy Policy</p>
        <p></p>
        <p>
          This Privacy Policy may be updated from time to time and will continue
          to be effective unless otherwise amended. If this Privacy Policy
          changes, those changes will be posted on the PesoOnline Facility
          and/or PesoOnline Application. The changes will take effect upon
          posting. Users of the PesoOnline Services and/or users accessing the
          PesoOnline Facilities are advised to periodically review the
          PesoOnline Website and/or PesoOnline Application to understand the
          policies and practices regarding information collection and use.
        </p>
        <p></p>
        <p>
          If there are material changes to this Privacy Policy that will affect
          certain personal data, the Company shall notify its users through the
          email addresses provided or by posting an announcement on the
          PesoOnline Application and/or PesoOnline Facilities.
        </p>
        <p></p>
        <p>
          The above consents and authorizations shall survive the termination of
          any loan agreement entered into by the Borrower or any other
          transactions, transactions, arrangements and accounts that the
          Borrower may have with or from the Company and Lenders through the
          PesoOnline Services and/or PesoOnline Facilities.
        </p>
        <p></p>
        <p>This version of the Privacy Policy was updated on August 22, 2019</p>
        <p></p>
        <p>Contact Us</p>
        <p></p>
        <p>
          If you have any questions, concerns or need for clarification
          regarding this Privacy Policy, you may contact the Company through the
          customer support system at dpo@peso-online.com.
        </p>
        <p></p>
        <p>
          I have read these Terms of Service and agree to all of the above terms
          and any amendments thereto. By clicking the "Accept" button below,
          and/or the "Register," "Connect," "Sign Up," or "Submit" button on any
          social media or public platform linked to the PESOONLINE Services, I
          understand that I am creating a digital signature, which I intend to
          have the same force and effect as my manual signature.
        </p>
        <p></p>
        <p>Accept</p>
        <p></p>
        <p>
          I confirm that I have read, understood and agree to the above privacy
          policy. Digital Signature
        </p>
        <p></p>
      </div>
    );
  }
}
export default PrivacyAgreement;
