import React from "react";
import { Router, Link } from "react-router-dom";
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory();

function Footer(props) {
  const contryKey = props.contryKey;
  if (props.isMobile) {
    return contryKey === "cl" ? (
      <footer id="footer" className="footer-mobile-wrapper">
        <div className="footer-bar">
          <div className="footer-title">Contáctanos </div>
          <div className="one-row one-row-first">
            <svg className="svgIcon1">
              <use xlinkHref="#iconmail1" />
            </svg>
            <span>service@pesoonline.com</span>
          </div>
        </div>
      </footer>
    ) : (
      <footer id="footer" className="footer-mobile-wrapper">
        <div className="footer-bar">
          <div className="footer-title">CASH AID LOAN ONLINE LENDING INC. </div>
          <div className="one-row one-row-first">
            <svg className="svgIcon1">
              <use xlinkHref="#iconmail1" />
            </svg>
            <span>service@pesoonline.com</span>
          </div>
          <div className="one-row">
            <svg className="svgIcon2">
              <use xlinkHref="#iconzizhi1" />
            </svg>
            <span>Company Registration: No.2021080022523-00</span>
          </div>
          <div className="one-row">
            <svg className="svgIcon2">
              <use xlinkHref="#iconzizhi1" />
            </svg>
            <span>Certificate Of Authority: No.L-21-0044-32</span>
          </div>

          <div className="one-row">
            <svg className="svgIcon2">
              <use xlinkHref="#iconaddress2" />
            </svg>
            <span>
              2214 M. ADRIATICO STREET ZONE 78 DISTRICT V MALATE- BARANGAY
              720,CITY OF MANILA
            </span>
          </div>
        </div>
      </footer>
    );
  }

  return contryKey === "cl" ? (
    <footer id="footer" className="footer-wrapper">
      <div className="footer-bar">
        <div className="footer-content">
          <div className="one-item">
            <div className="item-title">Contáctanos</div>
            <div className="one-row">
              <svg>
                <use xlinkHref="#iconmail1" />
              </svg>
              <p>service@pesoonline.com</p>
            </div>
          </div>

          <div className="one-item">
            <div className="item-title mar30">Reglamentos</div>
            <Router history={browserHistory}>
              <Link to="/cusPrivacy?cty=cl" target="_blank">
                《 Acuerdo de Privacidad 》
              </Link>
              <Link to="/cusService?cty=cl" target="_blank">
                《 Acuerdo de Servicio 》
              </Link>
            </Router>
          </div>
        </div>
      </div>
      <div className="bottom-bar">
        Copyright © 2024 | PesoOnline | All Rights Reserved
      </div>
    </footer>
  ) : (
    <footer id="footer" className="footer-wrapper">
      <div className="footer-bar">
        <div className="footer-content">
          <div className="one-item">
            <div className="item-title">CASH AID LOAN ONLINE LENDING INC.</div>
            <div className="one-row">
              <svg>
                <use xlinkHref="#iconmail1" />
              </svg>
              <p>service@pesoonline.com</p>
            </div>
            <div className="one-row">
              <svg className="iconzizhi">
                <use xlinkHref="#iconzizhi1" />
              </svg>
              <p>Company Registration: No.2021080022523-00</p>
            </div>
            <div className="one-row">
              <svg className="iconzizhi">
                <use xlinkHref="#iconzizhi1" />
              </svg>
              <p>Certificate Of Authority: No.L-21-0044-32</p>
            </div>
            <div className="one-row">
              <svg className="iconzizhi">
                <use xlinkHref="#iconaddress2" />
              </svg>
              <p>
                2214 M. ADRIATICO STREET ZONE 78 DISTRICT V MALATE- BARANGAY
                720,CITY OF MANILA
              </p>
            </div>
          </div>

          <div className="one-item">
            <div className="item-title mar30">Regulations</div>
            <Router history={browserHistory}>
              <Link to="/cusCredit" target="_blank">
                《 PesoOnline Loan Policy》
              </Link>
              <Link to="/cusPrivacy?cty=ph" target="_blank">
                《Customer Privacy Clause》
              </Link>
              <Link to="/cusService?cty=ph" target="_blank">
                《Customer Service Clause》
              </Link>
            </Router>
          </div>
        </div>
      </div>
      <div className="bottom-bar">
        Copyright © 2024 | PesoOnline | All Rights Reserved
      </div>
    </footer>
  );
}

export default Footer;
